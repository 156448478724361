<template>
  <!-- <v-card class="elevation-0 mx-auto" width="100%" height="100%"> -->
  <v-app id="inspire">
    <v-container fluid>
      <v-row>
        <v-col>
          <v-navigation-drawer
            app
            color="zipay2"
            dark
            mobile-breakpoint="720">
            <v-list>
              <v-container>
                <v-row>
                  <v-col>
                    <v-img
                      src="https://res.cloudinary.com/zipay/image/upload/v1668069445/deposit-dashboard/Logo_Zipay_1_zonows.png"
                      width="50%"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-list>

            <v-divider></v-divider>

            <v-list nav dense>
              <v-list-item-group
                v-model="selectedItem"
                active-class="selectedTileActive"
                mandatory>
                <v-list-item
                  class="selectedTile"
                  v-for="item in items"
                  :key="item.title"
                  link
                  :to="item.path">
                  <v-list-item-icon>
                    <v-icon>
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="ListItemClass">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <template v-slot:append>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">{{ user.username }}</v-list-item-title>
                  <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <div class="pa-2">
                <v-select
                  v-if="user.prodStatus"
                  :items="env"
                  item-text="label"
                  item-value="value"
                  label="Environment"
                  outlined
                  hide-details="auto"
                  class="mb-2"
                  dense
                  v-model="selectedEnv"
                />
                <v-btn color="zipay" block @click="logout">
                  Logout
                </v-btn>
              </div>
            </template>
          </v-navigation-drawer>
          <v-main>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-fade-transition mode="out-in">
                    <router-view></router-view>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </v-container>
          </v-main>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
  <!-- </v-card> -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      env: [
        {value: 'demo', label: 'Demo'},
        {value: 'prod', label: 'Production'},
      ],
      selectedEnv: localStorage.getItem('env'),
      selectedItem: 0,
      selectedTileActive: {},
      selectedTile: {},
      items: [
        { title: 'Dashboard', icon: 'mdi-home', path: '/dashboard' },
        // { title: 'Topup', icon: 'mdi-list-box', path: '/topup' },
        { title: 'Top Up History', icon: 'mdi-cash-plus', path: '/topup' },
        // { title: 'Users', icon: 'mdi-account-outline', path: '/users' },
        // { title: 'QRIS', icon: 'mdi-qrcode-scan', path: '/qris' },
        { title: 'Cash Out', icon: 'mdi-cash-fast', path: '/cashout' },
        { title: 'Account', icon: 'mdi-account', path: '/account' },
        { title: 'Setting', icon: 'mdi-tune', path: '/setting' },
      ],
    };
  },
  computed: {
    ...mapGetters('authModule', ['user'])
  },
  watch: {
    selectedItem() {
      const selectedItem = this.selectedItem;
      const path = this.items[selectedItem].path;
      console.log(
        '🚀 | file: DashboardViews.vue | line 91 | selectedItem | path',
        path
      );
    },
    async selectedEnv(newValue, oldValue) {
      // console.log(newValue)
      localStorage.setItem('env', newValue)
      await this.checkBalance()
      location.reload()
    }
  },
  methods: {
    ...mapActions('authModule', ['checkBalance']),
    logout() {
      localStorage.clear()
      this.$router.push('/login')
    }
  }
};
</script>

<style>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: 0.5;
    position: absolute;
    width: 100%;
  }
</style>
